<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg11 pa-sm-4 pa-lg-6>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card
            class="mx-auto elevation-1 pa-6"
            align="center"
            outlined
          >
            <!---------------------add asset-------------------------->
            <v-layout wrap>
              <v-flex xs12>
                <v-form ref="form1" v-model="valid1">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      sm7
                      md6
                      lg7
                      pb-2
                      pb-md-0
                      class="text-left nsbold"
                      style="font-weight: 700; font-size: 20px"
                      >Incidental Report
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      md4
                      lg3
                      pb-2
                      pb-md-0
                      align-self-center
                      class="text-left nsregular"
                    >
                      <v-text-field
                        v-model="keyword"
                        dense
                        class="rounded-xl"
                        label="Search"
                        outlined
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm2
                      md2
                      pb-0
                      pb-sm-2
                      pb-md-0
                      px-1
                      align-self-center
                    >
                      <!-- <v-card rounded outlined  style="cursor: pointer" color="transparent" @click="adddialogue = true"> -->
                      <v-btn
                        block
                        dark
                        color="#766BC0"
                        class="rounded-lg hidden-sm-and-up"
                        @click="adddialogue = true"
                      >
                        <v-icon x-small>mdi-plus</v-icon>
                        <span
                          class="text-left nsregular"
                          style="font-weight: 400; font-size: 12px"
                          >Add Asset</span
                        ></v-btn
                      >
                      <v-btn
                        width="120px"
                        dark
                        style="text-transform: none; border-radius: 40px"
                        color="#766BC0"
                        @click="adddialogue = true"
                        class="hidden-xs-only"
                      >
                        <!-- class="rounded-lg hidden-xs-only" -->

                        <v-icon x-small>mdi-plus-circle-outline</v-icon>
                        <span
                          class="text-left nsregular"
                          style="font-weight: 400; font-size: 13px"
                          >Create New</span
                        ></v-btn
                      >
                      <!-- </v-card> -->
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout wrap pt-2 pb-4>
                <v-flex xs12 lg2 pr-lg-1>
                  <v-text-field
                    v-model="name"
                    dense
                    label="Name"
                    outlined
                    :rules="[rules.required]"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg2 pt-8 pt-lg-0 pr-lg-1>
                  <v-text-field
                    v-model="assetType"
                    dense
                    :rules="[rules.required]"
                    label="Asset Type"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg2 pt-8 pt-lg-0 pr-lg-1>
                  <v-text-field
                    v-model="description"
                    dense
                    label="Description"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>
                
                <v-flex xs12 lg3 pr-lg-12 pt-2 pt-lg-0>
                  <v-btn
                    block
                    dark
                    color="primary"
                    dense
                    @click="addCategory()"
                  >
                    Add <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex
                  xs12
                  lg3
                  pl-lg-12
                  align-self-center
                  class="text-left nsbold"
                  style="font-weight: 700; font-size: 20px"
                >
                  <v-text-field
                    v-model="keyword"
                    dense
                    class="rounded-xl"
                    label="Search"
                    outlined
                    hide-details
                    clearable
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
                </v-form>
              </v-flex>
              <v-flex xs12 pt-6 sm3 lg12 pl-lg-4 pb-2 pl-sm-2 pt-lg -2 text-right>
              <download-excel :data="myData" :fields="json_fields" v-if="myData.length > 0">
                <v-btn
                  width="155px"
                  dark
                  style="text-transform: none"
                  color="#766BC0"
                  class="hidden-xs-only"
                  >Download Excel</v-btn
                >
                <v-btn
                  width="155px"
                  dark
                  block
                  class="hidden-sm-and-up"
                  style="text-transform: none"
                  color="#766BC0"
                  >Download Excel</v-btn
                >
              </download-excel>
            </v-flex>
            </v-layout>
            <!---------------------------------------------------->
            <!-- <v-layout wrap>
              <v-flex
                xs12
                lg6
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
                >
              </v-flex>
            </v-layout> -->
            <!-- <v-layout py-3>
              <v-flex
                xs2
                class="text-left nsbold"
                style="font-weight: 700; font-size: 18px"
              >
                <span>No.</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Name</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Type</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Description</span>
              </v-flex>
            </v-layout>
            <v-layout
              v-for="(item, i) in student"
              :key="i"
              py-2
              :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
            >
              <v-flex
                xs2
                class="text-left regular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ i + 1 }}</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.name }}</span>
              </v-flex>
              <v-flex xs3 class="text-left nsregular"
                style="font-weight: 400; font-size: 18px">
                <span>{{ item.assetType }}</span>
                </v-flex>
                <v-flex xs3 class="text-left nsregular"
                style="font-weight: 400; font-size: 18px">
                <span>{{ item.description }}</span>
              </v-flex>
              <v-flex xs1 v-if="item">
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(curItem = item), (editdialogue = true)"
                    >mdi-circle-edit-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-flex xs1>
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(dialoge = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-divider></v-divider>
            </v-layout> -->

            <v-flex xs12 v-if="report.length>0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No.</th>
                      <th class="text-left">Admission No.</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Class</th>
                      <th class="text-left">Day</th>
                      <th class="text-left">Reason</th>
                      <th class="text-left">Apology</th>
                      <th class="text-left">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in report" :key="i">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>{{ value.admissionNo }}</td>
                      <td><router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + value.studentid._id"
                            >
                        {{ value.name }}</router-link></td>
                      <td>{{ value.studentid.studentclass.className }}</td>
                      <td>{{ formatDate(value.day) }}</td>
                      <td>{{ value.reason }}</td>
                      <td>
                        <v-btn
                          v-if="value.isApology == false"
                          small
                          outlined
                          color="#766BC0"
                          @click="(createdialogue = true), (apology = value)"
                        >
                          <!-- <v-icon small>mdi-plus</v-icon> Add Apology -->No
                          apology found
                        </v-btn>
                        <v-btn
                          v-if="value.isApology == true"
                          small
                          outlined
                          color="#766BC0"
                          @click="
                            (viewdialogue = true),
                              (apology2 = value._id),
                              getApology((id = apology2))
                          "
                        >
                          <v-icon small>mdi-plus</v-icon> View Apology
                        </v-btn>
                        <!-- <v-icon small
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-circle-edit-outline</v-icon
                        > -->
                      </td>
                      <td>
                        <v-icon
                          small
                          style="cursor: pointer"
                          @click="(dialoge = true), (curId = value._id)"
                          >mdi-delete-outline</v-icon
                        >
                      </td>

                      <!-- <td>{{ value.studentid[0].admissionNo }}</td>
                                <td>{{ value.clothid.clothType.name }}</td>
                                <td>{{formatDate(value.INdate)}}</td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex v-else pt-4>Currently No Report Found</v-flex>
          </v-card>
        </v-flex>
      </v-layout>
      <!-- delete -->
      <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- edit -->
      <!-- <v-form ref="form" v-model="valid">
        <v-dialog width="400px" v-model="editdialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Edit Item </v-toolbar-title>
            </v-toolbar>
            <v-layout wrap v-if="curItem">
              <v-flex xs12 px-4 pt-4>
                <v-text-field
                  v-model="curItem.floorName"
                  outlined
                  hide-details
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-text-field
                  v-model="curItem.floorNo"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-flex>
             
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="editCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form> -->
      <!-- add -->

      <!-- <v-form ref="form3">
        <v-dialog width="600px" v-model="createdialogue">
          <v-card width="600px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Add Apology </v-toolbar-title>
            </v-toolbar>
            <v-layout wrap pa-4>
              <v-flex xs6 pr-2>
                <template v-if="apology != null">
                <v-layout style="font-weight: 400" wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="apology.admissionNo"
                      dense
                      label="Admission No"
                      outlined
                      @change="stdetails()"
                      :rules="[(v) => !!v || 'Admission number is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="apology.name"
                      dense
                      disabled
                      label="Name"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="apology.classs"
                      dense
                      disabled
                      label="Class"
                      outlined
                      @change="stdetails()"
                      :rules="[(v) => !!v || 'Admission number is required']"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      v-model="apology.school"
                      dense
                      disabled
                      label="School"
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                </template>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-layout style="font-weight: 400" wrap fill-height>
                  <v-flex xs12>
                    <v-textarea
                      height="240px"
                      outlined
                      name="input-7-4"
                      label="report"
                      hide-details
                      v-model="description"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="createdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="addApology()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form> -->
      <v-layout wrap>
        <v-flex xs12 pt-4>
          <v-pagination
            small
            color="#766BC0"
            v-model="page"
            :length="Pagelength"
          >
          </v-pagination>
        </v-flex>
      </v-layout>
      <v-snackbar
        :timeout="3000"
        :value="true"
        absolute
        centered
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
      <v-form ref="form">
        <v-dialog width="600px" v-model="adddialogue">
          <v-card width="600px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Add Item </v-toolbar-title>
            </v-toolbar>
            <v-layout wrap pa-4>
              <v-flex xs6 pr-2>
                <v-layout style="font-weight: 400" wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="admissionNo"
                      dense
                      label="Admission No.."
                      outlined
                      @change="stdetails()"
                      :rules="[(v) => !!v || 'Admission number is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="name"
                      dense
                      disabled
                      label="Name"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="classs"
                      dense
                      disabled
                      label="Class"
                      outlined
                      @change="stdetails()"
                      :rules="[(v) => !!v || 'Admission number is required']"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      v-model="school"
                      dense
                      disabled
                      label="School"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 pl-2>
                <v-layout style="font-weight: 400" wrap fill-height>
                  <v-flex xs12>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="day"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="day"
                          label="Date"
                          outlined
                          readonly
                          dense
                          clearable
                          class="rounded-0"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="day"
                        no-title
                        scrollable
                        @change="$refs.menu.save(day)"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      height="200px"
                      outlined
                      name="input-7-4"
                      label="report"
                      hide-details
                      v-model="reason"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="adddialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="addCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-form ref="form2">
        <v-dialog width="600px" v-model="createdialogue">
          <v-card width="600px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Add Apology  </v-toolbar-title>
            </v-toolbar>
            <v-layout wrap pa-4>
              
              <!-- <v-flex xs6 pl-2>
                <v-layout style="font-weight: 400" wrap fill-height>
                  <v-flex xs12>
                    <v-textarea
                      height="175px"
                      outlined
                      name="input-7-4"
                      label="report"
                      hide-details
                      v-model="description"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-flex> -->
              <v-flex xs6 pl-2>
                <v-layout style="font-weight: 400" wrap fill-height>
                  <v-flex xs12>
                    <div class="form-group">
                  <label for="my-file">Select Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    @change="previewImage"
                    class="form-control-file"
                    id="my-file"
                  />

                  <v-layout wrap>
                    <v-flex>
                      <p>Preview Here:</p>
                      <template v-if="preview">
                        <img
                          :height="
                            $vuetify.breakpoint.name == 'xs'
                              ? '250px'
                              : $vuetify.breakpoint.name == 'sm'
                              ? '400px'
                              : $vuetify.breakpoint.name == 'md'
                              ? '400px'
                              : $vuetify.breakpoint.name == 'lg'
                              ? '340px'
                              : '350px'
                          "
                          :src="preview"
                          class="img-fluid"
                        />
                      </template>
                    </v-flex>
                  </v-layout>
                </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="createdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="uploadImage(apology._id)"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-form ref="form3">
        <v-dialog width="400px" v-model="viewdialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title>Incident Description</v-toolbar-title>
            </v-toolbar>
            <v-layout wrap pa-4>
              <!-- <v-flex xs12 pr-2 v-if="item.photo">
                <v-img height="400px" contain :src="mediaURL + apology.photo"> </v-img>
              </v-flex> -->

              <v-flex xs12 pa-1>
                <!-- {{photo}} -->
                <v-img height="400px" contain :src="mediaURL + photo"> 
                  <v-btn color="#766BC0" @click="onDownloadClick(photo)"
><v-icon>mdi-download</v-icon>
</v-btn>  </v-img>
              </v-flex>

              <!-- <v-flex xs6 pl-2>
                <v-layout style="font-weight: 400" wrap fill-height>
                  <v-flex xs12>
                    <v-textarea
                      height="175px"
                      outlined
                      name="input-7-4"
                      label="report"
                      hide-details
                      v-model="description"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-flex> -->
            </v-layout>

            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="viewdialogue = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="snackbar"
      > <span style="font-size: 20px"> {{ msg }}</span>
     </v-snackbar>
    </v-flex>
  </v-layout>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      student: [],

      page: 1,
      Pagelength: 0,
      dialoge: false,
      //   editdialogue: false,
      adddialogue: false,
      createdialogue: false,
      viewdialogue: false,
      showsnackbar: false,
      preview: null,

      menu: null,
      valid1: null,
      snackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      report: [],
      photo: null,
      keyword: null,
      day: null,
      reason: null,
      description: null,
      studentid: null,
      apology: null,
      apology2: null,
      admissionNo: null,
      name: null,
      json_fields: {
        Ad_No: "adno",
        Name: "name",
        Class: "class",
        Date: "date",
        Reason: "reason",
      },
      myData: [],
      excel: {
        adno: "",
        name: "",
        class: "",
        date: "",
        reason: "",
      },
      classs: null,
      school: null,
      formData: new FormData(),
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.getData();
    this.getExcel();
  },
  watch: {
    keyword() {
      this.getData();
    },
    page() {
      // this.getData();
      this.getData();
    },
  },
  methods: {

    getExcel() {
      this.myData = [];
      this.appLoading = true;
      axios({
        method: "get",
        url: "/incident/report/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          //   issueStatus : a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.myData = [];
            this.outpass = response.data.data;
            //excel
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].studentid.admissionNo) {
                this.excel.adno = response.data.data[i].studentid.admissionNo;
              }
              if (response.data.data[i].studentid.name) {
                this.excel.name = response.data.data[i].studentid.name;
              }
              if (response.data.data[i].studentid.Class) {
                this.excel.class =
                  response.data.data[i].studentid.Class +
                  "-" +
                  response.data.data[i].studentid.division;
              }
              if (response.data.data[i].day) {
                this.excel.date = this.formatDate(response.data.data[i].day);
              }
              if (response.data.data[i].reason) {
                this.excel.reason = response.data.data[i].reason;
              }

              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};
            }
        
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/incident/report/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.report = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;

          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    previewImage: function (event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    onDownloadClick(item) {
      axios({
        method: "POST",
        url: "/purchase/getalllist",
        data: {
          id: this.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      console.log(item);
      var fileUrl = "https://chavara.leopardtechlabs.in/file/get/" + item;
      // console.log(fileUrl);
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.download = "image.jpeg";
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    },

    

    uploadImage(id) {
      this.appLoading = true;
      // this.formData.append("incidentId", this.apology._id);
      this.formData.append("id",id);
      // this.formData.append("studentid", this.apology.studentid._id);
       this.formData.append("photo", this.image);
      axios({
        method: "POST",
        url: "/upload/photo",
        headers: {
          token: localStorage.getItem("token"),
        },
        data:this.formData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formData = null;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.formData = new FormData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getApology() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/incident/report/get",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.apology2,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.photo = response.data.data.photo;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;

          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/incident/report/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.snackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //get student details
    stdetails() {
      axios({
        method: "post",
        url: "/admissionnumber/studentdetails",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          admissionNo: this.admissionNo,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.snackbar = true;
            this.name = response.data.data.name;
            this.classs = response.data.data.studentclass.className;
            this.school = response.data.data.school.SchoolName;
            this.studentid = response.data.data._id;
            this.admissionNo = response.data.data.admissionNo;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    //add button

    addCategory() {
      if (this.$refs.form.validate()) {
        axios({
          url: "/incident/report/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            day: this.day,
            reason: this.reason,
            admissionNo: this.admissionNo,
          },
        })
          .then((response) => {
            this.adddialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.reason = null;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }

            this.$refs.form1.resetValidation();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    addApology() {
      if (this.$refs.form2.validate()) {
        axios({
          url: "/apology/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            incidentId: this.apology._id,
            description: this.description,
            studentid: this.apology.studentid._id,
          },
        })
          .then((response) => {
            this.createdialogue = false;
            this.appLoading = false;
            if (response.data.status) {
             this.msg = response.data.msg;
            this.showsnackbar = true;
            this.description = null;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }

            this.$refs.form2.resetValidation();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    //edit button
    // editCategory() {
    //   axios({
    //     url: "/floor/name/edit",
    //     method: "POST",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       id: this.curItem._id,
    //       floorName: this.curItem.floorName,
    //       floorNo: this.curItem.floorNo,

    //     },
    //   })
    //     .then((response) => {
    //       this.editdialogue = false;
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         this.msg = "Updated Sucessfully";
    //         // this.$router.go(-1)
    //         this.showsnackbar = true;
    //         this.getData();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>